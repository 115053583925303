<template>
  <div class="layout-footer">
    <span class="footer-text" style="margin-right: 5px"><strong>Copyright &copy; {{new Date().getFullYear()}} Repeat English.</strong> All rights reserved.</span>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
</style>